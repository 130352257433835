@import url("tailwindcss/base");
@import url("tailwindcss/components");
@import url("tailwindcss/utilities");
@import url("martide/v3/public");
@import url("martide/v2/custom/datepicker");
@import url("martide/v2/custom/loading_page");
@import url("martide/v2/custom/tooltip");
@import url("martide/v2/custom/textarea");
@import url("martide/v2/tailwind_custom");
@import url("@glidejs/glide/dist/css/glide.core.min");
@import url("select2/dist/css/select2.min.css");
@import url("martide/v2/custom/select2");
@import url("country-flag-icons/3x2/flags.css");
@import url("./shared/select");
@import url("./shared/date_input");
@import url("pagination");
