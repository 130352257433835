@layer utilities {
  .h-100 {
    height: 27rem;
  }
}

.background-header {
  background: linear-gradient(
    180deg,
    rgb(255 255 255) 50%,
    rgb(243 244 246) 50%
  );
}

.exc-legend text {
  fill: grey;
  font-size: 0.55rem;
  stroke: none;
}

.exc-tick text {
  font-size: 0.4rem;
}

.exc-barlabel-in {
  font-size: 0.4rem;
}

.exc-barlabel-out {
  font-size: 0.4rem;
}

ul.bg-striped-colors li:nth-child(2n + 1) {
  @apply bg-gray-100;
}
