.select2-container {
  @apply w-full !important;
}

.select2-container.select2-container--disabled .select2-selection--single {
  @apply bg-gray-100 !important;
}

.select2-container.select2-container--disabled
  .select2-selection--single
  .select2-selection__rendered {
  @apply text-blue-dark text-opacity-50;
}

.select2-container .select2-selection--single {
  @apply h-12 outline-none !important;
  @apply px-4 py-3;
  @apply rounded-x border border-solid border-gray-300 !important;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  @apply pl-0 text-base font-normal leading-6 text-blue-dark;
}

.select2-container--focus.select2-container--default
  .select2-selection--single {
  @apply border-blue-500 !important;
}

.select2-selection.select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-selection.select2-selection--single .select2-selection__arrow::before {
  content: "";
  background: url("/images/select/chevron_down.svg") no-repeat;
  right: 0.75rem;
  top: 50%;

  @apply absolute h-6 w-6 text-xs leading-6 text-gray-400;
}

.select2-selection.select2-selection--single .select2-selection__arrow b {
  @apply hidden;
}

.select2-container--open
  .select2-selection--single
  .select2-selection__arrow::before {
  content: "";
  background: url("/images/select/chevron_down.svg") no-repeat;

  @apply h-6 w-6;
}

.select2-search--dropdown .select2-search__field {
  @apply rounded-x border border-solid border-gray-300 text-base text-blue-dark outline-none;
}

.select2-results__option[aria-selected="true"] {
  @apply flex items-center justify-between leading-6 text-blue-dark !important;
  @apply bg-white;
}

.select2-results__option[aria-selected="true"] span {
  @apply w-4/5 font-semibold;
}

.select2-results__option[aria-selected="true"]::after {
  content: "";
  background: url("/images/select/check.svg") no-repeat;

  @apply h-4 w-4;
}

.select2-results__option--highlighted[aria-selected] {
  @apply bg-blue-100 leading-6 text-blue-dark;
}

.select2-results__option[aria-selected] {
  @apply leading-6 text-blue-dark;
}

.select2-container--open .select2-dropdown--below {
  @apply mt-1 rounded-x border border-solid border-gray-300 shadow-md;
}

.select2-results__options {
  @apply overflow-auto;

  max-height: 250px;
}

.select2-selection--multiple {
  @apply max-h-40 min-h-12 overflow-y-auto outline-none !important;
}

.select2-selection--multiple .select2-selection__rendered {
  @apply flex-wrap !important;
  @apply flex !important;
}

.select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__clear {
  @apply hidden !important;
}

.select2-selection--multiple
  .select2-selection__rendered
  .select2-search__field {
  @apply mt-0 h-12;

  width: 100% !important;
}

.select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice {
  @apply m-2 rounded bg-blue-100 px-3 py-2 text-sm font-semibold text-blue-500;
}

.select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice__remove {
  @apply float-right pl-2;
}

.select2-selection--multiple
  .select2-selection__rendered
  .select2-search
  input {
  @apply text-base outline-none !important;
}

.img-flag {
  @apply inline-block h-6 w-6;
}

.select2-container--open .select2-dropdown {
  @apply -mt-1 !important;
  @apply rounded-t-none !important;
}

.select2-container--open .select2-dropdown .select2-results__options {
  @apply max-h-60 overflow-y-auto;
}

.select2-container--open
  .select2-dropdown
  .select2-results__options
  .select2-results__option {
  @apply px-4 !important;
  @apply py-2 !important;
}

.select2-container--open
  .select2-dropdown
  .select2-results__options
  .select2-results__option.select2-results__option--highlighted {
  @apply bg-blue-100 text-blue-500;
}

.select2-search,
.select-search .form-group {
  @apply w-full;
}

.select-search .form-group .select2-selection {
  @apply !border-0;
}

.select2-new-design .select2-container .select2-selection {
  border-radius: 5px !important;
  border: 1px solid #cad3df !important;
  background: white !important;
}

.select2-new-design
  .select2-container
  .select2-selection.select2-selection--multiple {
  overflow: hidden;
}

.select2-new-design
  .select2-container
  .select2-selection
  ul.select2-selection__rendered {
  padding: 5px 10px !important;
}

.select2-new-design
  .select2-container
  .select2-selection
  ul.select2-selection__rendered
  .select2-search__field {
  width: 100% !important;
  margin-top: 7px;
}

.select2-new-design
  .select2-container
  .select2-selection
  ul.select2-selection__rendered
  .select2-selection__choice {
  border: none;
  background: #d5edff;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 4px !important;
}

.select2-new-design .img-flag {
  @apply inline-block h-6 w-6;
}

.select2-data-template
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice {
  @apply py-1 !important;
  @apply my-1 !important;
  @apply leading-loose;
}

.disable-remove-icon .select2-selection__clear {
  @apply hidden;
}

.select2-selection__rendered .select2-selection__clear {
  float: right !important;
}

.employment_status .select2-selection__arrow {
  display: none !important;
}

select[readonly].select2-hidden-accessible + .select2-container {
  pointer-events: none;
  touch-action: none;
}

select[readonly].select2-hidden-accessible
  + .select2-container
  .select2-selection {
  background: #eee;
  box-shadow: none;
}

select[readonly].select2-hidden-accessible
  + .select2-container
  .select2-selection__arrow,
select[readonly].select2-hidden-accessible
  + .select2-container
  .select2-selection__clear {
  display: none;
}
