.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: theme("colors.blue.500");
  color: theme("colors.white");
  text-align: center;
  border-radius: 5px;
  padding: 6px 16px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  font-size: 1rem;
}

.tooltip .tooltiptext.right {
  left: 100% !important;
  top: 0;
  bottom: auto;
  margin-left: 10px;
  margin-top: -10px;
  width: 14rem !important;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: theme("colors.blue.500") transparent transparent;
}

.tooltip .tooltiptext.right::after {
  top: 38% !important;
  left: 0 !important;
  margin-left: -10px;
  transform: rotate(90deg);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
