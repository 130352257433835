.calendar {
  @apply relative;
}

form .labeled.calendar::after {
  top: 2.5rem;
}

form .calendar.open::after {
  @apply opacity-100 !important;

  background: url("/images/input/calendar-active.svg") no-repeat;
}

.flatpickr-calendar::before,
.flatpickr-calendar::after {
  @apply hidden;
}

.flatpickr-prev-month,
.flatpickr-next-month {
  @apply hidden;
}

.flatpickr-current-month {
  @apply left-0 pt-0 text-left text-sm leading-5 !important;
  @apply h-full w-full;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  @apply my-0 px-0 !important;
  @apply uppercase;

  left: 24px;
  top: 15px;
}

.flatpickr-current-month .numInputWrapper {
  top: 15px;
  left: 20px;
}

.flatpickr-current-month .numInputWrapper:hover,
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  @apply bg-blue-600 bg-opacity-12 !important;
}

.flatpickr-months {
  @apply justify-start border-b border-solid border-gray-300;
}

.flatpickr-months .flatpickr-month {
  height: 50px !important;
}

.flatpickr-calendar {
  @apply w-80 !important;
}

.flatpickr-innerContainer {
  @apply justify-center;
}

.flatpickr-rContainer {
  @apply w-68;
}

.dayContainer {
  @apply w-full min-w-full max-w-full !important;
}

.flatpickr-days {
  @apply w-full !important;
}

.flatpickr-days .flatpickr-day {
  @apply font-normal text-blue-dark !important;

  max-width: 38.857px !important;
  height: 38.857px !important;
  line-height: 38.857px !important;
}

.flatpickr-days .flatpickr-day.prevMonthDay,
.flatpickr-days .flatpickr-day.nextMonthDay {
  @apply text-opacity-50 !important;
}

.flatpickr-days .flatpickr-day.today {
  @apply border-none bg-gray-200 !important;
}

.flatpickr-days .flatpickr-day.selected {
  @apply border-none bg-blue text-white !important;
}

.flatpickr-days .flatpickr-day.inRange,
.flatpickr-days .flatpickr-day.prevMonthDay.inRange,
.flatpickr-days .flatpickr-day.nextMonthDay.inRange,
.flatpickr-days .flatpickr-day.today.inRange,
.flatpickr-days .flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-days .flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-days .flatpickr-day:hover,
.flatpickr-days .flatpickr-day.prevMonthDay:hover,
.flatpickr-days .flatpickr-day.nextMonthDay:hover,
.flatpickr-days .flatpickr-day:focus,
.flatpickr-days .flatpickr-day.prevMonthDay:focus,
.flatpickr-days .flatpickr-day.nextMonthDay:focus {
  @apply border-none bg-blue-600 bg-opacity-12 !important;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  @apply font-normal uppercase leading-5;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer span.flatpickr-weekday {
  @apply text-xs text-blue-dark !important;
}

.shortcut-buttons-flatpickr-wrapper.light {
  @apply absolute;

  right: 24px;
  top: 15px;
}

.shortcut-buttons-flatpickr-wrapper.light .shortcut-buttons-flatpickr-buttons {
  @apply leading-5;
}

.shortcut-buttons-flatpickr-wrapper.light .shortcut-buttons-flatpickr-button {
  @apply uppercase;
}
