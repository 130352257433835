@layer base {
  .transition-base {
    @apply transition duration-150 ease-in-out;
  }

  .disabled {
    @apply pointer-events-none border-gray-300 bg-gray-300 bg-opacity-25;
  }

  .checked {
    background: url("/images/input/check.svg") no-repeat center;
    background-size: 60% 60%;

    @apply border-transparent bg-current;
  }

  .checked:disabled {
    @apply disabled;

    background-image: url("/images/input/check-blur.svg");
  }

  .container {
    @apply mx-auto max-w-full px-6;
  }

  @screen xl {
    .container {
      max-width: 1158px;
    }
  }

  .footer-container {
    @apply mx-auto px-6;

    max-width: 1378px;
  }
}

.transition-base {
  @apply transition duration-150 ease-in-out;
}

.bg-blue,
.bg-blue-dark,
.bg-blue-900 {
  @apply text-white;
}

.btn {
  @apply transition-base flex items-center justify-center rounded border border-transparent text-base capitalize leading-6;
}

.btn-sm {
  @apply px-4 font-normal;
}

.btn-md {
  @apply px-5 py-2 font-medium;
}

.btn-lg {
  @apply px-8 py-3 font-bold;
}

.btn-primary {
  @apply h-12 rounded border border-blue-500 bg-blue-500 px-4 py-2 font-semibold text-white;
}

.btn-primary:hover {
  @apply bg-blue;
}

.btn-danger {
  @apply bg-danger text-white;
}

.btn-green {
  @apply bg-green text-white;
}

.btn-green:hover {
  @apply bg-green-600;
}

.btn-white {
  @apply bg-white text-blue-500;
}

.btn-white:hover {
  @apply bg-gray-400;
}

.btn-gray {
  @apply bg-gray-300 text-blue-900;
}

.btn-gray:hover {
  @apply opacity-75;
}

.btn-outline-primary {
  @apply h-12 rounded border border-blue-500 bg-transparent px-4 py-2 font-semibold text-blue-500;
}

.btn-outline-primary:hover {
  @apply border-transparent bg-blue-500 text-white;
}

.btn-outline-primary-no-hover {
  @apply h-12 rounded border border-blue-500 bg-transparent px-4 py-2 font-semibold text-blue-500;
}

.btn-primary-no-hover {
  @apply bg-blue-500 text-white;
}

.btn-remove:focus {
  @apply shadow-none;
}

.btn-remove::before {
  @apply inline-block h-6 w-6 bg-gray-400;

  content: "";
  mask: url("/images/icons/garbage_can.svg") no-repeat 50% 50%;
  mask-size: cover;
}

.btn-remove:hover::before {
  @apply bg-blue-600;
}

.link-base {
  @apply transition-base text-blue-500;
}

.link-base:hover {
  @apply text-blue;
}

.tag-base {
  @apply transition-base mb-2 mr-2 min-w-22 rounded bg-white px-3 py-1 text-gray-600;
}

.tag-base:hover {
  @apply text-blue-900;
}

.page-title {
  @apply bg-blue pb-6 pt-4 text-white;
}

.heading-2xl {
  @apply mb-4 text-4xl font-bold;
}

@screen sm {
  .page-title {
    @apply pb-12 pt-6;
  }

  .heading-2xl {
    @apply text-7xl;
  }
}

.header-link {
  @apply ml-3 px-3 py-2 font-semibold leading-6;
}

.header-sublink {
  @apply block px-6 py-2 font-semibold text-blue-900;
}

.sortable-header {
  @apply flex items-center justify-between;
}

.footer-link {
  @apply transition-base text-white opacity-75;
}

.footer-link:hover {
  @apply opacity-100;
}

.footer-icon {
  @apply transition-base text-2xl text-white opacity-75;
}

.footer-icon:hover {
  @apply opacity-100;
}

input.form-control,
input.time-select {
  @apply transition-base h-12 w-full appearance-none rounded-md border border-gray-300 bg-white px-4 py-3 text-base leading-6 text-gray-900;
}

input.form-control:focus,
input.time-select:focus {
  @apply border-blue-500 outline-none;
}

.form-control,
.time-select {
  @apply transition-base w-full appearance-none rounded-md border border-gray-300 bg-white px-4 py-3 text-base leading-6 text-gray-900;
}

.form-control:focus,
.time-select:focus {
  @apply border-blue-500 outline-none;
}

.required label::after {
  @apply inline text-red;

  content: " *";
}

.select2-selection {
  @apply rounded-md border border-gray-300 bg-white !important;
}

.column-heading {
  @apply text-sm font-semibold uppercase text-blue-dark text-opacity-50;
}

.cell {
  @apply break-words text-base font-normal leading-6 text-blue-dark;
}

.search-box {
  @apply text-blue-900;
}

.search-box .form-control,
.search-box .time-select {
  @apply rounded border border-gray-300;
}

.search-box .form-control .rbt-input-wrapper,
.search-box .time-select .rbt-input-wrapper {
  @apply text-left;
}

.search-box .form-control .rbt-input-wrapper .rbt-token,
.search-box .time-select .rbt-input-wrapper .rbt-token {
  @apply relative m-1 inline-block rounded bg-blue-100 p-2 outline-none;
}

.search-box .form-control .rbt-input-wrapper .rbt-input-hint-container,
.search-box .time-select .rbt-input-wrapper .rbt-input-hint-container {
  @apply inline-block;
}

.search-box .form-control .rbt-input-wrapper .rbt-input-hint-container div,
.search-box .time-select .rbt-input-wrapper .rbt-input-hint-container div {
  @apply rounded pl-2;
}

.search-box
  .form-control
  .rbt-input-wrapper
  .rbt-input-hint-container
  div
  input,
.search-box
  .time-select
  .rbt-input-wrapper
  .rbt-input-hint-container
  div
  input {
  @apply h-12 px-2 !important;

  width: 300% !important;
}

@keyframes autofill {
  0%,
  100% {
    @apply bg-transparent;
  }
}

.search-box
  .form-control
  .rbt-input-wrapper
  .rbt-input-hint-container
  div
  input:-webkit-autofill,
.search-box
  .time-select
  .rbt-input-wrapper
  .rbt-input-hint-container
  div
  input:-webkit-autofill {
  animation-delay: 1s;
  animation-name: autofill;
  animation-fill-mode: both;
}

.search-box .rbt-highlight-text {
  @apply bg-transparent font-bold;
}

.search-box .dropdown-menu {
  @apply z-10 float-left my-1 rounded border border-gray-300 bg-white px-2 text-left;
}

.search-box .dropdown-menu .dropdown-item {
  @apply block w-full px-2 py-4;
}

.table-auto td {
  @apply px-2 py-2;
}

.search-box-topbar {
  @apply text-blue-dark;
}

.search-box-topbar .form-control,
.search-box-topbar .time-select {
  @apply block w-full overflow-x-auto rounded border border-gray-300;

  height: 2.625rem;
}

.search-box-topbar .form-control-sm {
  @apply p-2 leading-normal;
}

.search-box-topbar .form-control .rbt-input-wrapper .rbt-token,
.search-box-topbar .time-select .rbt-input-wrapper .rbt-token {
  @apply m-0 px-2 py-0;
}

.search-box-topbar .rbt-input-wrapper {
  @apply overflow-hidden !important;
}

.search-box-topbar .rbt-input-main {
  width: fill-available !important;
  min-width: 1000% !important;
}

.search-box-topbar
  .form-control
  .rbt-input-wrapper
  .rbt-input-hint-container
  div
  input,
.search-box-topbar
  .time-select
  .rbt-input-wrapper
  .rbt-input-hint-container
  div
  input {
  @apply h-auto px-0 !important;
}

.sidebar .menu-icon-frame {
  @apply relative top-0.5 mr-2 h-5 w-5 flex-shrink-0;
}

.sidebar .menu-icon {
  @apply absolute h-full w-full;

  filter: brightness(0) invert(1) opacity(0.4);
}

.sidebar .sidebar-header-container {
  @apply pl-10 pr-6;

  height: 6vh;
}

.sidebar
  .select2-selection.select2-selection--single
  .select2-selection__rendered {
  @apply ml-4;
}

.sidebar
  .sidebar-client-dropdown
  .select2-container--default
  .select2-selection--single {
  @apply border-2 bg-blue-dark px-8 uppercase !important;

  border-color: rgb(255 255 255 / 40%) !important;
}

.sidebar
  .sidebar-client-dropdown
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  @apply w-full px-0 !important;

  color: rgb(255 255 255 / 40%);
}

.sidebar
  .sidebar-client-dropdown
  .select2-container--default
  .select2-selection--single:focus {
  @apply outline-none !important;

  border-color: white !important;
}

.sidebar
  .sidebar-client-dropdown
  .select2-selection.select2-selection--single
  .select2-selection__arrow::before {
  content: "";
  opacity: 0.5;
  background: url("/images/select/chevron-white.svg") no-repeat;
}

.sidebar-inactive
  .sidebar-client-dropdown
  .select2-selection.select2-selection--single
  .select2-selection__arrow::before {
  @apply hidden;
}

.sidebar .sidebar-client-dropdown .select2-dropdown {
  @apply border-2 bg-blue-dark uppercase !important;

  border-color: rgb(255 255 255 / 40%) !important;
}

.sidebar
  .sidebar-client-dropdown
  .select2-container--default
  .select2-search--dropdown
  .select2-search__field {
  @apply border-2 bg-blue-dark text-white !important;

  border-color: rgb(255 255 255 / 40%) !important;
  caret-color: white;
}

.sidebar
  .sidebar-client-dropdown
  .select2-container--default
  .select2-search--dropdown
  .select2-search__field:focus {
  @apply border-white outline-none !important;
}

.sidebar .sidebar-client-dropdown .select2-results__option {
  color: rgb(255 255 255 / 40%) !important;
}

.sidebar
  .sidebar-client-dropdown
  .select2-container--default
  .select2-results__option[aria-selected="true"] {
  @apply bg-transparent !important;
}

.sidebar
  .sidebar-client-dropdown
  .select2-container--default
  .select2-results__option[aria-selected="true"]
  span {
  @apply text-white !important;
}

.sidebar
  .sidebar-client-dropdown
  .select2-container--default
  .select2-results__option[aria-selected="true"]::after {
  content: none;
}

.sidebar
  .sidebar-client-dropdown
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  @apply bg-transparent text-white !important;
}

.sidebar
  .sidebar-client-dropdown
  .select2-selection.select2-selection--single
  .select2-selection__rendered::before {
  content: "";
  opacity: 0.5;
  background: url("/images/select/eye.svg") no-repeat;

  @apply absolute left-2 mx-2 h-6 w-6;
}

.sidebar-inactive
  .sidebar-client-dropdown
  .select2-selection.select2-selection--single
  .select2-selection__rendered::before {
  @apply left-0 mx-1 h-5 w-5;
}

.sidebar-inactive .sidebar-menu-container {
  height: 94vh;
  overflow: hidden;
  padding: 1rem 0.5625rem 2rem 2.5rem;
}

.sidebar-inactive
  .sidebar-client-dropdown
  .select2-selection.select2-selection--single {
  @apply -ml-1 !h-10 !px-0 !py-2;
}

.sidebar-inactive
  .sidebar-client-dropdown
  .select2-selection.select2-selection--single
  .select2-selection__rendered
  span,
.sidebar-inactive
  .sidebar-client-dropdown
  .select2-selection.select2-selection--single
  .select2-selection__arrow {
  @apply hidden;
}

.sidebar .sidebar-menu-container {
  padding: 1.5rem;
}

.sidebar .sidebar-menu-container:hover {
  overflow-y: scroll;
}

.sidebar .sidebar-menu-container::-webkit-scrollbar {
  width: 14px;
}

.sidebar .sidebar-menu-container::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 7px;
  background-color: rgb(255 255 255 / 20%);
}

.sidebar .sidebar-menu-container::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

.sidebar .sidebar-menu-container::-webkit-scrollbar-corner {
  background-color: transparent;
}

.sidebar .notify-badge {
  @apply flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-white;

  shape-outside: circle();
  clip-path: circle();
  font-size: 0.5625rem;
}

.sidebar .sidebar-menu-group-container li {
  @apply leading-6;
}

.sidebar .sidebar-menu-group-container li:not(:last-child) {
  @apply mb-4;
}

.sidebar
  .sidebar-menu-group-container
  .sidebar-menu-group:last-of-type:not(:only-of-type) {
  @apply mb-0;
}

.scroll-bar {
  scrollbar-color: #808080 transparent;
  overflow: hidden !important;
}

.scroll-bar::-webkit-scrollbar-track {
  display: none;
}

.scroll-bar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}

.scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #808080;
}

.scroll-bar:hover {
  overflow: overlay !important;
}

@document url-prefix("") {
  .scroll-bar {
    overflow-y: scroll !important;
  }
}

@supports (-webkit-appearance: none) {
  .sidebar .sidebar-menu-container:hover {
    overflow-y: overlay !important;
  }
}

.sidebar-menu-group {
  @apply mb-4;
}

.sidebar-menu-group span:first-of-type:not(.full-name) {
  @apply opacity-40;
}

.sidebar-menu-group li a {
  @apply flex items-start;
}

.sidebar-menu-group a:hover .menu-icon,
.sidebar-menu-group .active .menu-icon {
  filter: brightness(0) invert(1);
}

.sidebar-menu-group a:hover span,
.sidebar-menu-group .active span {
  @apply text-white opacity-100 !important;
}

.sidebar-menu-group .avatar {
  @apply mr-3;
}

.sidebar-menu-group-main {
  margin-bottom: 7.25rem;
}

.sidebar-menu-group-main .sidebar-menu-group:not(:last-child) {
  margin-bottom: 3.375rem;
}

.sidebar-inactive {
  @apply !w-15;
}

.sidebar-inactive .sidebar-menu-group li {
  @apply text-center;
}

.sidebar-inactive .sidebar-menu-group li a {
  @apply justify-center;
}

.sidebar-inactive .menu-icon-frame {
  @apply mr-0 !important;
}

.sidebar-inactive .collapse-menu img {
  @apply origin-center rotate-180 transform transition duration-300 ease-in-out;
}

.sidebar-inactive .notification-link {
  @apply relative;
}

.sidebar-inactive .notify-badge {
  @apply absolute left-4 right-0 top-0 h-2.5 w-2.5 bg-blue-500 text-transparent;
}

.sidebar-inactive .sidebar-header-container,
.sidebar-inactive .sidebar-menu-container {
  padding-left: 0.9rem;
  padding-right: 0.9rem;
}

.sidebar-inactive .avatar {
  @apply mr-0 !important;
}

@media (width >=640px) {
  .inner-content {
    width: calc(100% - theme("width.60"));
  }

  .inner-content.active {
    width: calc(100% - theme("width.15"));
  }
}

@media (width <=640px) {
  .inner-content {
    width: 100%;
  }
}

.badge {
  @apply inline-block rounded-x text-center text-sm font-semibold leading-5;
}

.badge-pill {
  @apply rounded-full;
}

.badge-primary {
  @apply bg-blue-500 bg-opacity-15 text-blue-500;
}

.badge-success {
  @apply bg-green bg-opacity-15 text-success;
}

.badge-danger {
  @apply bg-danger bg-opacity-15 text-danger;
}

.badge-secondary {
  @apply bg-gray-400 bg-opacity-15 text-blue-dark text-opacity-60;
}

.alert {
  @apply box-border flex rounded-x border border-solid px-6 py-4;
}

.alert-info {
  @apply border-info bg-white text-info;
}

.alert-danger,
.alert-error {
  @apply border-danger bg-red-100 text-danger;
}

.alert-warning {
  @apply border-warning bg-yellow-100 text-warning;
}

.alert-success {
  @apply border-success bg-green-100 text-success;
}

.preload .sidebar,
.preload .collapse-menu img,
.preload .inner-content {
  @apply transition-none;
}

.form-status[data-status="cancelled"] {
  @apply bg-gray-400 bg-opacity-15 text-gray-400;
}

.form-status[data-status="completed"] {
  @apply bg-green bg-opacity-15 text-green;
}

.form-status {
  @apply bg-blue-500 bg-opacity-15 text-blue-500;
}

.modal {
  @apply transition-opacity duration-150 ease-linear;
}

.modal-active {
  @apply overflow-x-hidden overflow-y-hidden !important;
}

.help-block {
  @apply text-xs font-normal leading-5 text-danger !important;
}

[x-cloak] {
  @apply hidden;
}

.dropdown-selection.selected::after {
  @apply h-4 w-4;

  content: "";
  background: url("/images/select/check.png") no-repeat center;
}

.self-baseline {
  align-self: baseline;
}

input[disabled] {
  @apply bg-gray-300;
}

button:disabled {
  @apply cursor-not-allowed opacity-50;
}

strong {
  @apply font-semibold;
}

.toggle-checkbox:checked {
  @apply right-0 border-2 border-solid border-blue-500;
}

.toggle-checkbox:checked + .toggle-label {
  @apply bg-blue-500 !important;
}

.table-custom {
  @apply mb-4 w-full text-blue-dark;
}

.table-custom thead tr:nth-child(1) th {
  @apply top-0 bg-blue-100 py-5;
}

.table-custom thead tr th {
  @apply border border-gray-200 px-3 py-2;
}

.table-custom tbody td {
  @apply border-l border-gray-200 px-3 py-2;
}

.table-custom tbody td:nth-child(1) {
  @apply border-l-0 border-gray-200;
}

.table-hover tbody tr {
  @apply cursor-pointer;
}

.table-hover tbody tr:hover {
  @apply bg-blue-100;
}

.table-sticky-header {
  border-collapse: separate;
  border-spacing: 0 !important;
}

.table-sticky-header thead tr {
  @apply border-b !important;
}

.table-sticky-header thead tr:nth-child(1) th {
  @apply top-0 !important;
  @apply bg-blue-100 !important;
}

.table-sticky-header thead tr:nth-child(2) th {
  top: 61px;

  @apply border-b bg-white !important;
}

.table-sticky-header thead tr th {
  @apply sticky z-10 border-gray-200;
}

.collapsed .arrow {
  transform: rotate(180deg) !important;
}

input:disabled,
input:read-only:not(.flatpickr, [type="checkbox"], [type="radio"]) {
  @apply bg-gray-100 text-blue-dark text-opacity-50;
}

.form-group label {
  @apply text-xs leading-5 text-blue-dark;
}

.form-group.form-group-error:not(.phx-no-feedback) label,
.form-group.form-group-error:not(.phx-no-feedback) input {
  @apply text-danger;
}

.form-group.form-group-error .hide-error ~ .help-block,
.form-group.form-group-error.phx-no-feedback .help-block {
  @apply hidden;
}

.filter-dropdown .calendar label {
  @apply mb-1 text-xs leading-5;
}

.filter-dropdown .calendar {
  @apply mb-4;
}

.filter-dropdown .calendar::after {
  @apply top-10 !important;
}

.checkbox {
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  user-select: none;
  flex-shrink: 0;
  border-radius: 0.1875rem;

  @apply h-4 w-4 cursor-pointer border border-opacity-50 bg-white;
}

.checkbox:focus {
  @apply border-opacity-100 outline-none;
}

.checkbox:disabled:not(.checked-checkbox) {
  @apply disabled;
}

.checkbox:checked {
  @apply checked;
}

.checkbox:hover {
  @apply checked cursor-pointer border-current border-opacity-100 bg-white;

  background-image: url("/images/input/check.svg") no-repeat;
}

.checkbox-primary {
  @apply border-blue-500 border-opacity-50 text-blue-500;
}

.checkbox-primary:hover {
  @apply border-blue-500;
}

.checkbox-primary:checked:hover {
  @apply bg-blue;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

.time {
  @apply relative;
}

form .time::after {
  @apply absolute h-4 w-4 opacity-50;

  content: "";
  background: url("/images/input/time.svg") no-repeat center;
  top: 1rem;
  right: 1rem;
}

form .labeled.time::after {
  top: 2.5rem;
}

.list-signers .list-group-item:last-child {
  @apply border-b border-gray-200;
}

.signer-selections .signer-selections-item:last-child {
  @apply border-none;
}

.file-attachment {
  @apply my-4 w-2/3 text-sm;

  outline: 2px dashed #ccc;
  min-width: 35%;
}

.file-attachment label {
  @apply block text-blue-500;
}

.file-attachment input {
  @apply hidden;
}

.file-attachment-dragover {
  outline: 3px dashed #ccc;
  outline-offset: 5px;
}

#add_document {
  @apply text-base font-semibold text-blue-500;
}

#add_attachment {
  @apply ml-8 font-semibold text-blue-500;
}

.modal.fade {
  @apply hidden;
}

.modal.show {
  @apply block;
}

.input-group .form-control,
.input-group .time-select {
  @apply w-1/2 !important;
  @apply rounded-r-none align-top;
}

.input-group .select2-container {
  @apply w-1/2 !important;
}

.input-group .select2-container .select2-selection.select2-selection--single {
  @apply rounded-l-none !important;
  @apply border-l-0 !important;
  @apply align-top;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  @apply !text-gray-500;
}

.step-process-form ol > li:not(:last-child) div:first-child::before,
.step-process-form ol > li:not(:last-child) div:first-child::after {
  content: "";
  display: inline-block;
  border-width: 29px;
  border-style: solid;
  width: 0;
  height: 0;
  left: 100%;
  top: -1px;
  position: absolute;
  z-index: 1;
}

.step-process-form ol > li {
  @apply relative m-0 table-cell h-14 border border-gray-200 pl-5 text-center;
}

.step-process-form ol > li:first-child {
  @apply pl-0 !important;
}

.step-process-form ol > li > div {
  @apply relative h-full w-full leading-14;
}

.step-process-form ol {
  @apply relative mx-auto table w-3/4 p-0;
}

.step-process-form ol > li:not(:last-child) div:first-child::after {
  border-color: transparent transparent transparent #e5ecf4;
}

.step-process-form ol > li:not(:last-child) div:first-child::before {
  border-width: 27px;
  display: block;
  border-color: transparent transparent transparent #fff;
  z-index: 2;
  top: 1px;
}

.appearance-n {
  appearance: none !important;
}

[id^="filter-"] .flatpickr-wrapper,
.modal-body .flatpickr-wrapper {
  @apply w-full;
}

.social-media-grid {
  grid-template-columns: 1rem 1fr;
}

.note-grid {
  grid-template-columns: 3rem 1fr;
}

.position-grid {
  grid-template-columns: 1rem 2fr 1fr 1fr;
}

.attachment-grid {
  grid-template-columns: 1.5rem 1fr;
}

.list-seafarers-grid {
  grid-template-columns: 1fr 6rem repeat(2, 1fr) 6rem repeat(2, 1fr);
}

#profile-tabs::-webkit-scrollbar {
  @apply hidden;
}

a[data-scroll-to].active,
.primary-tab.active {
  @apply border-b-4 border-t-4;

  border-color: transparent transparent theme("colors.blue.500");
}

progress[value] {
  @apply rounded-x;
}

progress[value]::-webkit-progress-bar {
  @apply rounded-x bg-gray-200;
}

progress[value]::-webkit-progress-value {
  @apply rounded-x bg-green;
}

.blend-luminosity {
  mix-blend-mode: luminosity;
}

.public-mobile {
  @apply h-auto max-w-full;

  width: 53%;
}

.public-mobile.public-mobile-front {
  object-position: 0 2.8rem;
}

.public-mobile.public-mobile-back {
  object-position: 0 4.5rem;
}

@screen md {
  .public-mobile.public-mobile-front {
    object-position: 0 5.6rem;
  }

  .public-mobile.public-mobile-back {
    object-position: 0 9rem;
  }
}

.public-tools-hero-img {
  min-height: 33.4825rem;
}

.table-spacing-0 {
  border-spacing: 0;
}

#invoices_table .month-row td,
#invoices_table .invoice-row td {
  @apply border-b border-gray-200;
}

#invoices_table .month-row.last-row td,
#invoices_table .invoice-row.last-row td {
  @apply border-0;
}

#invoices_table .month-row:nth-child(2) td:first-child {
  @apply rounded-tl-2sm;
}

#invoices_table .month-row:nth-child(2) td:last-child {
  @apply rounded-tr-2sm;
}

input[type="search"]::after {
  @apply h-4 w-4;

  content: "";
  background: url("/images/public-pages/employers/search_icon.svg") no-repeat
    center;
}

.paragraph-form-type {
  li {
    @apply border-b border-gray-200;
  }

  li:nth-last-child(2) {
    @apply border-0;
  }
}

#select2-new-broadcast-msg-form_to-results {
  display: none !important;
}

.color-name {
  background-color: #eff6ff !important;
}

.list-color-buttons button {
  margin-left: 8px;
  display: inline-block;
}

.list-color-buttons button:first-child {
  margin-left: 0 !important;
}

.w-15 {
  width: 3.75rem !important;
}

.text-blue-500 {
  --tw-text-opacity: 1;

  color: rgb(57 139 237 / var(--tw-text-opacity)) !important;
}

.modal-no-border {
  .modal-header,
  .modal-footer {
    border: none !important;
  }
}

.phx-loading-show {
  display: none;
}

.phx-loading-hide {
  display: inline-block;
}

.phx-submit-loading > .phx-loading-show {
  display: inline-block;
}

.phx-submit-loading > .phx-loading-hide {
  display: none;
}

.signer-selection-cols {
  grid-template-columns: 1rem repeat(5, minmax(0, 1fr));
}

#travel-arrangements-card .departure-date {
  @apply -mx-4 bg-blue-100 bg-opacity-50 px-4 sm:-mx-6 sm:px-6;
}

#travel-arrangements-card .travel-arrangements {
  @apply !pt-0;
}

.shadow-card {
  box-shadow: 0 2px 8px 0 rgb(48 99 136 / 15%);
}

.signer-icon {
  @apply !flex flex-col items-center;
}

.signer-icon label {
  @apply text-xs leading-5 sm:hidden;
}

.error-content {
  min-height: 100vh;
}

.public .error-content {
  min-height: auto;
}

.modal-container {
  @apply !z-50;
}

.ce-block__content,
.ce-toolbar__content {
  @apply max-w-full w-full;
}
